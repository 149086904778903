<template>
  <div id="talento">
    <div v-show="showData">
      <Section1 />

      <Section2
        v-if="!!varData"
        :var-data="varData.section2"
      />
      <Section3
        v-if="!!varData"
        :var-data="varData.section3"
      />
      <Section4
        v-if="!!varData"
        :var-data="varData.section4"
      />
      <Section5
        v-if="!!varData"
        :var-data="varData.section5"
      />
      <Section6
        v-if="!!varData"
        :var-data="varData.section6"
      />
    </div>
  </div>
</template>

<script>
import Section1 from './components/talento/Section1';
import Section2 from './components/talento/Section2';
import Section3 from './components/talento/Section3';
import Section4 from './components/talento/Section4';
import Section5 from './components/talento/Section5';
import Section6 from './components/talento/Section6';

export default {
    name: 'Talento',
    components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6
    },
    data() {
        return {
            varData: null,
            showData: false
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            const body = {
                filter: {
                    _id: 'Kc6mXauT5kSfQYkEP'
                },
                params: {
                    collection: 'pages'
                }
            };
            let url = process.env.VUE_APP_API + '/api/get';
            await this.axios
                .post(url, body)
                .then(response => {
                    if (response.status == 200) {
                        let data = response.data;
                        let desc = data.description
                            .replace('<p>', '')
                            .replace('</p>', '');
                        data = JSON.parse(desc);
                        if (this.$i18n.locale == 'es') {
                            this.varData = data.es;
                        } else if (this.$i18n.locale == 'en') {
                            this.varData = data.en;
                        }
                        this.showData = true;
                    } else {
                        this.result = response.message;
                    }
                })
                .catch(error => {
                    this.result = error;
                });
        }
    }
};
</script>
<style scoped lang="scss">
@import '../../assets/css/stlProyecto.css';
</style>
