<template>
  <div
    id="section2"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4">
        <b-row align-v="center">
          <b-col
            md="6"
            sm="12"
          >
            <div
              class="cardd fondoImg"
              data-aos="fade-right"
            >
              <b-img :src="img" />
            </div>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <div
              class="cardd fondoText"
              data-aos="fade-left"
            >
              <utilComp
                var-key="h5"
                var-tipo="h5c"
                :var-data="varData.h5c"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import utilComp from '../utils/utilContenido';
export default {
    components: {
        utilComp
    },
    props: {
        varData: {
            default: () => {},
            type: Object
        }
    },
    data() {
        return {
            img: require('@/assets/images/talento/row-of-business-people.jpg')
        };
    }
};
</script>

<style lang="scss">
#section2 {
    .cardd {
        border-radius: 2em;
    }
    .fondoImg {
        margin-left: 5em;
        margin-right: -5em;
        img {
            border-radius: 2em;
        }
        @media (max-width: 768px) {
            margin: 0;
        }
    }
    .fondoText {
        background: linear-gradient(rgb(0, 112, 168), rgb(0, 124, 124));
        padding: 2em;
        color: whitesmoke;
        margin: 0 -5em;
        width: calc(90%);
        @media (max-width: 768px) {
            margin: 0;
            width: 100%;
        }
        @media (max-width: 425px) {
            margin: 2em auto 0;
            width: 100%;
        }
    }
}
</style>
