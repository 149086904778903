<template>
  <div :key="varKey">
    <h1 v-if="varTipo == 'h1'">
      {{ varData }}
    </h1>
    <h1 v-else-if="varTipo == 'h1c'">
      <span
        v-for="(item, index) in varData"
        :key="index"
      >
        <span v-if="item.type == 'txt'">{{ item.value }}</span>
        <strong v-if="item.type == 'strong'">{{ item.value }}</strong>
      </span>
    </h1>
    <h2 v-else-if="varTipo == 'h2'">
      {{ varData }}
    </h2>
    <h2 v-else-if="varTipo == 'h2c'">
      <span
        v-for="(item, index) in varData"
        :key="index"
      >
        <span v-if="item.type == 'txt'">{{ item.value }}</span>
        <strong v-if="item.type == 'strong'">{{ item.value }}</strong>
      </span>
    </h2>
    <h3 v-else-if="varTipo == 'h3'">
      {{ varData }}
    </h3>
    <h3 v-else-if="varTipo == 'h3c'">
      <span
        v-for="(item, index) in varData"
        :key="index"
      >
        <span v-if="item.type == 'txt'">{{ item.value }}</span>
        <strong v-if="item.type == 'strong'">{{ item.value }}</strong>
      </span>
    </h3>
    <h3 v-else-if="varTipo == 'h3s'">
      <strong>{{ varData }}</strong>
    </h3>
    <h3 v-else-if="varTipo == 'h3i'">
      <i>{{ varData }}</i>
    </h3>
    <h4 v-else-if="varTipo == 'h4'">
      {{ varData }}
    </h4>
    <h4 v-else-if="varTipo == 'h4c'">
      <span
        v-for="(item, index) in varData"
        :key="index"
      >
        <span v-if="item.type == 'txt'">{{ item.value }}</span>
        <strong v-if="item.type == 'strong'">{{ item.value }}</strong>
      </span>
    </h4>
    <h5 v-else-if="varTipo == 'h5'">
      {{ varData }}
    </h5>
    <h5 v-else-if="varTipo == 'h5c'">
      <span
        v-for="(item, index) in varData"
        :key="index"
      >
        <span v-if="item.type == 'txt'">{{ item.value }}</span>
        <strong v-if="item.type == 'strong'">{{ item.value }}</strong>
      </span>
    </h5>
    <p v-else-if="varTipo == 'txt'">
      {{ varData }}
    </p>
    <p v-else-if="varTipo == 'txts'">
      <span
        v-for="(item, index) in varData"
        :key="index"
      >
        <h2 v-if="item.type == 'h2'">{{ item.value }}</h2>
        <h3 v-else-if="item.type == 'h3'">{{ item.value }}</h3>
        <h4 v-else-if="item.type == 'h4'">{{ item.value }}</h4>
        <h5 v-else-if="item.type == 'h5'">{{ item.value }}</h5>
        <span v-else-if="item.type == 'txt'">{{ item.value }}</span>
        <strong v-else-if="item.type == 'strong'">{{
          item.value
        }}</strong>
      </span>
    </p>
    <span v-else-if="varTipo == 'txtls'">
      <p
        v-for="(item, index) in varData"
        :key="index"
      >{{ item }}</p>
    </span>
    <ul v-else-if="varTipo == 'list'">
      <li
        v-for="(item, index) in varData"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
    <ul v-else-if="varTipo == 'listc'">
      <li
        v-for="(item, index) in varData"
        :key="index"
      >
        <span
          v-for="(item1, index1) in item.value"
          :key="'t' + index1"
        >
          <span v-if="item1.type == 'txt'">{{ item1.value }}</span>
          <strong v-if="item1.type == 'strong'">{{
            item1.value
          }}</strong>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    props: ['varKey', 'varData', 'varTipo'],
    mounted() {}
};
</script>
