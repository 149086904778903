<template>
  <div id="section3">
    <div class="HeadContainer">
      <div class="HeadVertical-center">
        <b-container fluid>
          <b-container>
            <div
              class="cardd"
              data-aos="fade-down"
            >
              <utilComp
                var-key="div"
                var-tipo="txts"
                :var-data="varData.txts"
              />
            </div>
          </b-container>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import utilComp from '../utils/utilContenido';
export default {
    components: {
        utilComp
    },
    props: ['varData']
};
</script>

<style lang="scss">
#section3 {
    color: whitesmoke;
    background: linear-gradient(rgba(0, 0, 0, 0.4)),
        url('~@/assets/images/talento/aicad_el_futuro_de_las_asesorias_en_la_era_digital.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;

    .cardd {
        background: linear-gradient(rgba(0, 0, 0, 0.7));
        width: 65%;
        padding: 3em;
        border-radius: 2em;
        margin: 0 auto !important;
        @media (max-width: 768px) {
            width: 70%;
        }
        @media (max-width: 425px) {
            width: 80%;
            padding: 2em;
        }
    }
}
</style>
