<template>
  <div
    id="section5"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4 text-center">
        <utilComp
          var-key="h2"
          var-tipo="h2"
          :var-data="varData.title"
        />
        <utilComp
          var-key="h4"
          var-tipo="h4"
          :var-data="varData.txt"
        />
        <br>
        <div
          class="cardd"
          data-aos="zoom-out-down"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <a :href="url">
            <b-img :src="img" />
          </a>
        </div>
        <br>
        <b-row align-h="center">
          <b-col
            v-for="(item, index) in lugares"
            :key="index"
            md="3"
            sm="6"
          >
            <div
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
            >
              <a
                class="btn buttonAzul-outline btn-block"
                :href="item.url"
              >{{ item.lugar }}</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import utilComp from '../utils/utilContenido';
export default {
    components: {
        utilComp
    },
    props: ['varData'],
    data() {
        return {
            img: require('@/assets/images/talento/BB-RCB-DEF4.jpg'),
            url: '/business-break/',
            lugares: [
                {
                    lugar: 'Barcelona',
                    url: '/barcelona-centro/'
                },
                {
                    lugar: 'Bilbao',
                    url: '/bilbao/'
                },
                {
                    lugar: 'Castellón',
                    url: '/castellon/'
                },
                {
                    lugar: 'La Rioja',
                    url: '/larioja/'
                },
                {
                    lugar: 'Rioja-Navarra',
                    url: '/rioja-navarra/'
                },
                {
                    lugar: 'Las Palmas',
                    url: '/'
                },
                {
                    lugar: 'Madrid Juan Bravo',
                    url: 'https://www.aicad-madrid.es/'
                },
                {
                    lugar: 'Madrid Leganes',
                    url: '/madrid/'
                },
                {
                    lugar: 'Navarra',
                    url: '/navarra/'
                },
                {
                    lugar: 'Oviedo',
                    url: '/lugones/'
                },
                {
                    lugar: 'Vigo',
                    url: '/vigo/'
                },
                {
                    lugar: 'Zaragoza',
                    url: '/zaragoza/'
                }
            ]
        };
    }
};
</script>

<style lang="scss">
#section5 {
    .cardd {
        margin: 0 auto;
        max-width: 600px;
        img {
            border-radius: 1em;
        }
    }
    .buttonAzul-outline {
        -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
        margin: 1em 0;
    }
}
</style>
