<template>
  <div id="section6">
    <b-container fluid>
      <b-container class="text-center">
        <b-row class="py-5">
          <b-col sm="12">
            <utilComp
              var-key="h2"
              var-tipo="h2"
              :var-data="varData.title"
            />
            <h4>
              <utilComp
                var-key="txtls"
                var-tipo="txtls"
                :var-data="varData.txtls"
              />
            </h4>
            <br>
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="text-left"
          >
            <form
              v-if="!sentSuccess"
              id="formulario"
              method="POST"
              action="#"
              @submit.prevent="onSubmit"
            >
              <b-form-input
                id="nombre"
                v-model="form.nombre"
                class="mt-1"
                name="nombre"
                :placeholder="$t('incorporateTalento.form.nombre')"
                required
              />
              <b-form-input
                id="empresa"
                v-model="form.empresa"
                class="mt-1"
                name="empresa"
                :placeholder="$t('incorporateTalento.form.empresa')"
                required
              />
              <b-form-input
                id="email"
                v-model="form.email"
                class="mt-1"
                name="email"
                type="email"
                :placeholder="$t('incorporateTalento.form.email')"
                required
              />
              <b-form-input
                id="telef"
                v-model="form.telef"
                class="mt-1"
                name="telef"
                :placeholder="$t('incorporateTalento.form.telef')"
                required
              />
              <b-form-group>
                <label>{{ $t('incorporateTalento.form.modalidad') }}</label>
                <b-form-radio-group
                  id="modalidad"
                  v-model="form.modalidad"
                  :options="modalidadArr"
                  name="modalidad"
                />
              </b-form-group>
              <b-form-checkbox
                class="mt-1"
                @change="aceptPolitica"
              >
                <span
                  v-for="(item, index) in acept"
                  :key="index"
                >
                  <span v-if="item.type == 'txt'">{{ item.value }}</span>
                  <a
                    v-if="item.type == 'link'"
                    :href="urlAviso"
                    style="color: whitesmoke !important; text-decoration: underline;"
                  >{{ item.value }}</a>
                </span>
              </b-form-checkbox>
              <span
                v-for="(item, index) in list"
                :key="index"
              >
                <span v-if="item.type == 'txt'">{{ item.value }}</span>
                <a
                  v-if="item.type == 'link'"
                  :href="urlAviso"
                  style="color: whitesmoke !important; text-decoration: underline;"
                >{{ item.value }}</a>
              </span>
              <p class="mt-1">
                {{ $t('incorporateTalento.form.nota') }}
              </p>
              <b-button
                class="buttonWhite-outline mt-1 mb-4"
                :disabled="enviar == 0"
                type="submit"
              >
                {{ $t('incorporateTalento.form.btn') }}
              </b-button>
            </form>
            <div
              v-if="sentSuccess"
              class="send-success"
            >
              <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
              <h3 style="color:#fff;">
                {{ $t('common_contact_form.will_contact') }}
              </h3>
              <div
                class="contact-info text-center mt-4"
                style="color:#fff"
              >
                <h4>Puedes contactarme por</h4>
                <div class="contact-nfo">
                  <font-awesome-icon
                    icon="phone"
                    class="phone-icon"
                  />
                  <a
                    href="tel:+34928354668"
                    style="color:#fff"
                  >+34 928 354 668</a>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="col2"
          >
            <utilComp
              var-key="h2"
              var-tipo="h2"
              :var-data="varData.title1"
            />
            <br>
            <utilComp
              var-key="h3"
              var-tipo="h3"
              :var-data="varData.txt1"
            />
            <br>
            <font-awesome-icon
              :icon="['fab', 'facebook']"
              size="8x"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import utilComp from '../utils/utilContenido';
export default {
    components: {
        utilComp
    },
    props: ['varData'],
    data() {
        return {
            form: {
                nombre: null,
                empresa: null,
                email: null,
                telef: null,
                modalidad: null,
                acept: null,
                web: null,
                locate: this.$i18n.locale
            },
            sentSuccess: false,
            sentFailed: true,
            enviar: 0,
            urlAviso: '/aviso-legal-privacidad/'
        };
    },
    computed: {
        txts() {
            return this.$t('incorporateTalento.txts');
        },
        modalidadArr() {
            return this.$t('incorporateTalento.form.modalidadSelect');
        },
        acept() {
            return this.$t('incorporateTalento.form.acept');
        },
        list() {
            return this.$t('incorporateTalento.form.list');
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.enviar = 0;
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [
                        { name: 'Form Type', value: 'Incorpora Talento Form' },
                        { name: 'Nombre', value: this.form.nombre },
                        { name: 'Email', value: this.form.email },
                        { name: 'Empresa', value: this.form.empresa },
                        { name: 'Telefono', value: this.form.telef },
                        { name: 'Modalidad', value: this.form.modalidad },
                        { name: 'Reference Link', value: window.location.href },
                        { name: 'Aceptación Privacidad RGDP:', value: this.form.acept }
                    ]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                        this.form = {
                            nombre: null,
                            empresa: null,
                            email: null,
                            telef: null,
                            modalidad: null,
                            acept: null
                        };
                        setTimeout(() => {
                            this.sentSuccess = false;
                        }, 10000);
                    } else {
                        this.sentFailed = true;
                        let send = this.$t('sendERROR');
                        this.$bvToast.toast(send, {
                            title: this.$t('message.rs'),
                            variant: 'default',
                            autoHideDelay: 3000,
                            solid: true
                        });
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        },
        aceptPolitica(checked) {
            this.enviar = checked == 1;
        }
    }
};
</script>

<style lang="scss">
div.send-success {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
}
#section6 {
	background: linear-gradient(rgb(0, 112, 168), rgb(0, 124, 124));
	color: whitesmoke;

	.col2 {
		text-align: right !important;
		@media (max-width: 768px) {
			text-align: center !important;
		}
	}
}
</style>
