<template>
  <div id="section4">
    <div class="m-4 text-center">
      <utilComp
        var-key="h3"
        var-tipo="h3i"
        :var-data="varData.title"
      />
    </div>
    <div class="fondoS4">
      <b-img :src="img" />
    </div>
  </div>
</template>

<script>
import utilComp from '../utils/utilContenido';
export default {
    components: {
        utilComp
    },
    props: ['varData'],
    data() {
        return {
            img: require('@/assets/images/talento/BB-Master-Talento-2020.jpg')
        };
    }
};
</script>

<style lang="scss">
.fondoS4 {
    img {
        width: 100%;
    }
}
</style>
